@import 'src/assets/css/sass/variables';
@import 'src/assets/css/sass/mixins';

.root {
  margin-top: 15%;

  @include respond-above(sm) {
    max-width: 680px;
    width: 680px;
  }
}

.container {
  margin: 24px;
  position: relative;
  text-align: center;
}

.header {
  h2 {
    color: $theme-color-default-17;
    font-family: $font2;
    font-size: 48px;
    font-weight: 700;
    margin: 0 0 24px 0;

    @include respond-below(xs) {
      font-size: 36px;
      margin-top: 40px;
    }
  }
}

.close {
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.image {
  @include respond-below(xs) {
    margin: 40px 0;
    width: 141px;
  }
}

.button {
  background: $theme-color-default-12;
  color: $foreground-color;
  font-size: 18px;
  height: 48px;
  margin-top: 28px;
  width: 200px;

  @include respond-below(xs) {
    margin-bottom: 52px;
    width: 100%;
  }
}
