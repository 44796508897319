@import 'src/assets/css/sass/variables';
@import 'src/assets/css/sass/mixins';

$barThemes: (
  Blue: (
    $theme-color-default-15,
    $theme-color-default-16,
  ),
  Gold: (
    $theme-color-default-18,
    $theme-color-portrait,
  ),
  Tiffany: (
    $theme-color-default-10,
    $theme-color-default-9,
  ),
);

// @todo Use $levelColors from _variables.scss
$levelThemes: (
  Easy: $theme-color-default-7,
  Medium: $theme-color-default-2,
  Advanced: $theme-color-default-6,
);

.root {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.level {
  border: 6px solid #000;
  border-bottom: none;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.levelLabel {
  background-color: #ccc;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 10px 40px;
  font-weight: bold;
  font-size: 12px;
  line-height: 1em;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #fff;
}

.actions {
  display: flex;
  width: 100%;
  height: 47px;
  background-color: #000;
  color: #fff;
}

.action {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  transition: background-color 320ms;

  &:link,
  &:active,
  &:visited {
    color: inherit;
  }

  &:focus {
    outline: none;
  }

  // Add hover state if the action is a link
  &[href]:hover {
    text-decoration: none;
    background-color: transparentize(#fff, 0.9);
  }

  > * + * {
    margin-left: 20px;
  }

  &:not(:last-child)::after {
    position: absolute;
    top: 9px;
    right: 0;
    bottom: 9px;
    display: block;
    content: '';
    width: 2px;
    background-color: #ccc;
  }
}

.actionLabel {
  display: none;

  @include respond-above(md) {
    display: block;
  }
}

@each $name, $theme in $barThemes {
  .theme#{$name} {
    .level {
      border-color: nth($theme, 1);
    }

    .actions {
      background-color: nth($theme, 1);
    }

    .action:not(:last-child)::after {
      background-color: nth($theme, 2);
    }
  }
}

@each $name, $color in $levelThemes {
  .levelType#{$name} {
    .levelLabel {
      background-color: $color;
    }
  }
}
