@import 'src/assets/css/sass/variables';
@import 'src/assets/css/sass/mixins';

.root {
  display: flex;
  align-items: center;
  color: $theme-color-default-11;
  font-family: $font1;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;

  @include respond-above(xs) {
    font-size: 16px;
    justify-content: flex-end;
  }
}

.title {
  margin-right: 30px;
}

.action {
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  appearance: none;
  transition: opacity 320ms;

  &:hover {
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }
}
