$theme-color-portrait: #d29950 !default;
$theme-color-default-1: #033553 !default;
$theme-color-default-2: #f0c509 !default;
$theme-color-default-3: #545762 !default;
$theme-color-default-4: #e5e5e5 !default;
$theme-color-default-5: #f0f0f0 !default;
$theme-color-default-6: #f06056 !default;
$theme-color-default-7: #56f09d !default;
$theme-color-default-8: #56f09d !default;
$theme-color-default-9: #118c9e !default;
$theme-color-default-10: #096775 !default;
$theme-color-default-11: #545762 !default;
$theme-color-default-12: #09537c !default;
$theme-color-default-13: #dcdde0 !default;
$theme-color-default-14: #c5893d !default;
$theme-color-default-15: #1f4c68 !default;
$theme-color-default-16: #376582 !default;
$theme-color-default-17: #09133d !default;
$theme-color-default-18: #c4893d !default;

$theme-color-1: #145388 !default;
$theme-color-2: #1d477a !default;

$levelColors: (
  easy: (
    main: $theme-color-default-7,
    alt: #8becc0,
  ),
  medium: (
    main: $theme-color-default-2,
    alt: #FFE781,
  ),
  advanced: (
    main: $theme-color-default-6,
    alt: #ff9c95,
  ),
);

$info-color: #3195a5 !default;
$success-color: #3e884f !default;
$warning-color: #b69329 !default;
$error-color: #c43d4b !default;

$rootFolder: '' !default;
$shadow-offsets-top: 1 3 10 14 19 !default;
$shadow-blurs-top: 2 6 10 14 19 !default;
$shadow-opacities-top: 0.1 0.3 0.6 0.7 0.8 !default;

$shadow-offsets-bottom: 1 3 6 10 15 !default;
$shadow-blurs-bottom: 3 6 6 5 6 !default;
$shadow-opacities-bottom: 0.1 0.3 0.6 0.7 0.8 !default;
$foreground-color: #ffffff !default;
$separator-color-light: #f3f3f3 !default;
$separator-color: #d7d7d7 !default;

$primary-color: #3a3a3a !default;
$secondary-color: #8f8f8f !default;
$muted-color: #909090 !default;

//$background-color: #f8f8f8 !default;
$background-color: #e5e5e5 !default;

$border-radius: 0.1rem !default;
$border-radius-rounded: 0.75rem !default;

$dark-btn-background: #8d8d8d !default;
$light-btn-background: #e4e4e4 !default;
$button-text-color: #d0d0d0 !default;

$main-margin: 60px !default;
$main-margin-lg: 50px !default;
$main-margin-md: 40px !default;
$main-margin-xs: 15px !default;

$navbar-height: 60px !default;
$navbar-height-xs: 60px !default;
$navbar-height-md: 60px !default;
$navbar-height-lg: 60px !default;

$sub-menu-width: 230px !default;
$sub-menu-width-lg: 230px !default;
$sub-menu-width-md: 230px !default;
$sub-menu-width-xs: 230px !default;

//Responsive Breakpoints
$breakpoints: (
  xxs: 420px,
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1440px,
) !default;

$font1: 'Macklin Text';
$font2: 'Macklin Text Bold';
$font3: 'Macklin Sans';
