// Mixin to prefix several properties at once
// @author Florin ONEA
// @param {Map} $declarations - Declarations to prefix
// @param {List} $prefixes (()) - List of prefixes to print
@mixin prefix($declarations, $prefixes: ()) {
  @each $property, $value in $declarations {
    @each $prefix in $prefixes {
      #{'-' + $prefix + '-' + $property}: $value;
    }
    #{$property}: $value;
  }
}

// Gives a card depth effect.
// @param {Number} $depth - depth level (between 1 and 5)
// @link http://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality Google Design
// @requires {function} top-shadow
// @requires {function} bottom-shadow
@mixin depth($depth) {
  @if $depth < 1 {
    box-shadow: none;
  } @else if $depth > 5 {
    @warn "Invalid $depth `#{$depth}` for mixin `card`.";
  } @else {
    box-shadow: bottom-shadow($depth), top-shadow($depth);
  }
}
@mixin mBlur($depth) {
}

// Computes a top-shadow for a card effect.
// @param {Number} $depth - depth level
// @return {List}

@function top-shadow($depth) {
  $primary-offset: nth($shadow-offsets-top, $depth) * 1px;
  $blur: nth($shadow-blurs-top, $depth) * 4px;
  $color: rgba(black, nth($shadow-opacities-top, $depth));

  @return 0 $primary-offset $blur $color;
}

// Computes a bottom-shadow for a card effect.
// @param {Number} $depth - depth level
// @return {List}
@function bottom-shadow($depth) {
  $primary-offset: nth($shadow-offsets-bottom, $depth) * 1px;
  $blur: nth($shadow-blurs-bottom, $depth) * 5px;
  $color: rgba(black, nth($shadow-opacities-bottom, $depth));
  @return 0 $primary-offset $blur $color;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: '';
    clear: both;
  }
}

@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get(
      $breakpoints,
      $breakpoint
    ); // Write the media query.
    @media (min-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get(
      $breakpoints,
      $breakpoint
    ); // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

@function encodecolor($string) {
  @if type-of($string) == 'color' {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote('#{$hex}');
  }
  $string: '%23' + $string;
  @return $string;
}

.color-1 {
  color: $foreground-color;
}
.style-font-1 {
  font-family: $font1;
  font-size: 16px;
  font-style: italic;
}
.style-font-2 {
  font-family: $font3;
  font-size: 12px;
}
.style-font-3 {
  font-family: $font3;
  font-size: 18px;
}

// Sets a grayscale filter.
// @param {Number} $value - [0-100%]
@mixin grayscale($value) {
  -webkit-filter: #{'grayscale(#{$value})'};
  -moz-filter: #{'grayscale(#{$value})'};
  filter: #{'grayscale(#{$value})'};
}
