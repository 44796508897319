@import 'src/assets/css/sass/variables';
@import 'src/assets/css/sass/mixins';

.image {
  display: flex;
  justify-content: center;
}

.bar {
  height: 65px;
  background-color: $theme-color-default-1;
}
