@import 'src/assets/css/sass/variables';
@import 'src/assets/css/sass/mixins';

.root {
  background-color: $theme-color-default-5;
}

.titleContainer {
  padding: 30px 0;
  background-color: $foreground-color;
}

.title {
  font-family: $font3;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 120%;
  letter-spacing: 0.025em;
  color: $theme-color-default-1;
}

.articles {
  // Position and min-height are required for the <Loader>.
  position: relative;
  min-height: 400px;
  overflow: hidden;

  // @todo Move to child component (cards)
  a {
    text-decoration: none;
  }
}

.slider {
  overflow: visible !important;
}

.slide {
  padding: 40px 10px 50px;
  height: initial !important;
}
