@import 'src/assets/css/sass/variables';
@import 'src/assets/css/sass/mixins';

.root {
  font-family: $font1;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.025em;
  color: $theme-color-default-3;
  margin-bottom: 40px;

  @include respond-above(md) {
    margin-bottom: 45px;
  }
}

.author {
  font-weight: bold;
  margin-bottom: 5px;
}

.authorName {
  text-transform: uppercase;
}
