@import 'src/assets/css/sass/variables';
@import 'src/assets/css/sass/mixins';

.root {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba($theme-color-1, 0.2);
  border-radius: 50%;
  border-top-color: $theme-color-1;
  animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  z-index: 1;

  &.fixed {
    position: fixed;
  }

  &.absolute {
    position: absolute;
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
