@import 'src/assets/css/sass/variables';
@import 'src/assets/css/sass/mixins';

.root {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: $theme-color-default-17;
  box-shadow: -6px 9px 20px rgba(0, 0, 0, 0.2);
  transition: all 320ms;

  &:hover {
    text-decoration: none;
    color: $theme-color-default-17;
    box-shadow: -6px 9px 30px rgba(0, 0, 0, 0.4);
  }

  &.autoHeight {
    height: 100%;
  }
}

.image {
  height: 375px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.level {
  width: 120px;
  height: 40px;
  margin: -22px auto 12px;
  background-color: #fff;
  padding: 4px;
  border-radius: 7px;
}

.levelLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: #ccc;
  border-radius: 4px;
  padding: 10px 40px;
  font-size: 12px;
  line-height: 1em;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #fff;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 18px;
  line-height: 1em;
  letter-spacing: 0.07em;
}

.actionLabel {
  display: inline-block;
  margin-left: 20px;
}

.title {
  margin-top: auto;
  padding: 0 20px 20px;
}

.supTitle {
  margin: 0 0 10px 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.3em;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.mainTitle {
  margin: 0;
  font-weight: bold;
  font-size: 26px;
  line-height: 1.2em;
  letter-spacing: 0.025em;
}

@each $level, $color in $levelColors {
  .#{$level} {
    .levelLabel {
      background-color: map-get($color, 'main');
    }

    .actions svg path {
      fill: map-get($color, 'main');
    }

    .image {
      background-color: map-get($color, 'alt');
    }
  }
}
