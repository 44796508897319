@import 'src/assets/css/sass/variables';
@import 'src/assets/css/sass/mixins';

.root {
  position: relative;
}

.imageEmpty.root {
  @include respond-above(md) {
    height: initial;
  }
}

.overlay {
  display: flex;
  flex-direction: column;

  @include respond-above(md) {
    position: relative;
    min-height: 90vh;
    width: 50%;
  }
}

.imageEmpty .overlay {
  background: #118c9e;
  padding-top: 60px;

  @include respond-above(md) {
    position: static;
    width: 100%;
    min-height: initial;
  }
}

.imagePortrait .overlay {
  background-color: #d29950;
}

.imageLandscape .overlay {
  background-color: #5a7f95;

  @include respond-above(md) {
    transform: translate3d(-100%, 0, 0);
    background-color: transparentize(#5a7f95, 0.2);
    backdrop-filter: blur(6px);
    animation-name: slide-in-from-left;
    animation-delay: 2s;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
}

.image {
  height: 50vh;

  @include respond-above(md) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: initial;
  }

  img {
    display: block;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

.imagePortrait .image {
  @include respond-above(md) {
    padding-left: 50%;
  }
}

.content {
  flex: 1;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  display: flex;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  padding: 27px 20px;
  color: #fff;

  > *:last-child {
    margin-bottom: 0;
  }

  @include respond-above(md) {
    padding: 80px 70px;
  }
}

.categories {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 133%;
  letter-spacing: 2px;
  text-transform: uppercase;

  @include respond-above(md) {
    font-size: 18px;
    margin-bottom: 50px;
  }
}

.categoriesDecorator {
  flex: 1;
  height: 1px;
  background-color: #fff;
}

.categoriesText {
  display: block;
  flex-shrink: 0;
  padding: 0 30px;
}

.title {
  margin: 0 0 20px 0;
  padding: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: 0.015em;

  @include respond-above(md) {
    font-size: 64px;
    margin-bottom: 55px;
  }
}

.intro {
  font-family: $font1, serif;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;

  > * {
    margin-top: 0;
    margin-bottom: 0;

    & + * {
      margin-top: 1em;
    }
  }

  @include respond-above(md) {
    font-size: 20px;
  }
}

.widget {
  display: none;
}

.navigator {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
}

@keyframes slide-in-from-left {
  100% {
    transform: translate3d(0, 0, 0);
  }
}
